import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby";

import Layout from "../components/layout"
import { TikLink } from "../components/helper"
import logo from "../images/made-by-logo.svg";
import paper_plane from "../images/paper_plane.svg";

import { rootPath, rootCTAPath } from "../utils/linkResolver";

const ContactFormSucessPage = ({ location, data, pageContext }) => {
  const lang = pageContext.langKey;

  const t = data.prismic.allHomepages.edges[0].node;

  return <Layout location={location} data={data} lang={lang} hideFooter={true}>
    {/*<SEO title="test" description="test" />*/}

    <section className="contact-form-success">
      <Link className="success-made-by-logo made-by-logo" to={rootPath({ lang })}>
        <img alt="made.by Logo" src={logo} />
      </Link>
      <div className="row">
        <div className="col-12">

          <h1>{t.success_headline}</h1>
          <p>{t.success_message} </p>

          {/*<TikLink to={rootPath({lang: lang})}>{t.success_back_button}</TikLink>*/}
          <TikLink to={rootCTAPath({ lang: lang })}>{t.success_back_button}</TikLink>
        </div>
        <div className="col-12">
          <img className="paper-plane" alt="flying paper plane" src={paper_plane} />
        </div>
      </div>

    </section>
  </Layout>
}

ContactFormSucessPage.propTypes = {
  lang: PropTypes.string,
  data: PropTypes.object
}

// THE 'prismic { allAuthors {...}} IS NOT NEEDED, just to prevent validation error ('$lang is defined but never used')
export const query = graphql`
  fragment ContactFormSuccessFragment on Query {
    prismic {
      ...fragmentPrismicLayout
    }
    prismic {
      allHomepages(lang: $lang) {
        edges {
          node {
            title
            metaDescription
            letsgo_form_success
            success_headline
            success_message
            success_back_button
          }
        }
      }

    }
  }

  query($lang: String = "de-de") {
    ...ContactFormSuccessFragment
  }
`

export default ContactFormSucessPage
