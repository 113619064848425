import ContactFormSucessPage from './contact-form-success';

export const query = graphql`
query($lang: String = "en-gb") {
  prismic {
    allAuthorss(lang: $lang) {
      edges {
        node {
          firstname
          lastname
          image
          _linkType
        }
      }
    }
  }
  
  ...ContactFormSuccessFragment
}
`;

export default ContactFormSucessPage;
